/*  home-blocks
    ========================================================================== */
    .home-blocks {
        position: relative;
        margin: 33px 0 34px 0;
        padding: 6vh 0;
        @extend .gradient;
        &:before {
            content: "";
            z-index: 50;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 100%;
            width: 100%;
            height: 33px;
            background: url('/images/grass-top.svg');
            background-position: center top;  
            background-size: cover;  
        }
        &:after {
            content: "";
            z-index: 50;
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            width: 100%;
            height: 34px;
            background: url('/images/grass-bottom.svg');
            background-position: center bottom;  
            background-size: cover;  
        }
        .owl-carousel { padding-top: 60px;}

        /* leaves */
        .leaves {
            z-index: 100;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            width: 25vw;
            max-width: 105px;
            margin: 0 auto -75px auto;
            animation: shake 1s infinite both;
        }
        @keyframes shake {
            0% { transform: rotate(0);}
            50% { transform: rotate(-5deg);}
            100% { transform: rotate(0);}
        }

    }

/*  footer
    ========================================================================== */
    &.home .footer {
        .page-ending {
            margin-top: 100px;
            .leaves {
                display: block;
            }
            &:after {
                display: block;
            }
        }
    }