.header {
    background-color: $white;
    box-shadow: 6px 6px 12px rgba($black, 0.15);
    .header-main {
        .column.navbar {
            min-height: 90px;
            justify-content: flex-end;
            padding: 5px 15px 10px 15px;

            @include media-breakpoint-up(xl) {
                padding: 5px 40px 15px 40px;
            }

            .navbar-toggler {
                @extend .position-relative;
                z-index: 10;

                display: flex;
                text-align: center;

                @extend .btn;
                margin: 0;

                .navbar-toggler-icon {
                    margin-right: 10px;
                }
                &:hover {
                    background-color: $green-dark;
                    border-color: $green-dark;
                    color: $white;
                }
            }
        }

        /* logo */
        .logo {
            position: absolute;
            top: 15px;
            left: 15px;

            @extend .d-flex;
            @extend .align-items-center;
            @extend .justify-content-center;
            width: 100%;
            max-width: 125px;

            transition: .5s;

            @include media-breakpoint-up(sm) {
                max-width: 200px;
                height: 110px;
                padding: 0 35px;

                background-image: url('/images/logo-bg.png');
                background-size: cover;
            }
            @include media-breakpoint-up(lg) {
                top: 30px;
                max-width: 275px;
                height: 150px;
                padding: 0 50px;
            }
            @include media-breakpoint-up(xl) {
                left: 40px;

                max-width: 310px;
                height: 170px;
            }

            &.logo-overlay {
                top: 15px;
                z-index: 999;

                max-width: 125px;
                height: auto;
                padding: 0;
                
                background-image: none;

                @include media-breakpoint-up(md) {
                    top: 65px;
                    left: 95px;

                    max-width: 200px;
                }
            }
        }

        /* language */
        .site_switcher {
            @extend .position-relative;
            z-index: 10;

            width: 100%;

            .language {
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;

                &::before {
                    @extend .d-block;
                    order: 1;

                    margin: 0 15px 0 0;
                    padding: 0 15px 0 .5rem;
                    border-right: 1px solid $green-light;

                    content: '045 - 532 17 47';
                    
                    font-family: $font-family-roboto-slab;
                    font-size: 13px;
                    color: $green;
                }

                &::after {
                    order: 0;
                    @extend .d-block;

                    content: '\f095';
                    
                    font-family: "Font Awesome 5 Pro";
                    font-size: 13px;
                    font-weight: $font-weight-bold;
                    color: $green;
                }

                .nav-item {
                    order: 3;

                    font-size: 12px;
                    text-transform: uppercase;
                    font-family: $font-family-roboto-slab;
                    
                    .nav-link {
                        color: $green-light;
                        padding-right: 0.5rem;
                        padding-left: 0.5rem;
                    }
                    &.active {
                        .nav-link {
                            color: $green;
                        }
                    }
                }
            }
        }

        /* book-button */
        .book-button {
            @extend .position-relative;
            z-index: 10;

            margin: 0 15px 0 auto;
            background-color: $white;

            border-color: $orange;
            color: $orange;

            @include media-breakpoint-down(xs) {
                display: none;
            }

            &:hover {
                background-color: $orange;
                color: $white;
            }
        }

        .nav-overlay {
            @extend .position-fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            display: none;
            width: 100%;
            min-height: 100%;

            background-color: rgba(#2D4C60, .9);

            &::after {
                @extend .position-absolute;
                right: 0;
                bottom: 0;
                left: 0;

                width: 100%;
                height: 330px;

                background-image: url('/images/menu-overlay-bg.png');
                background-position: center bottom;
                background-repeat: repeat-x;
                background-size: auto 330px;

                content: '';
            }

            .navbar-nav {
                @extend .position-relative;
                z-index: 10;

                @extend .flex-column;
                @extend .h-100;
                overflow-x: auto;

                padding: 90px 0;

                background-color: $white;

                @include media-breakpoint-up(sm) {
                    max-width: 175px;
                    overflow-x: unset;
                }

                @include media-breakpoint-up(md) {
                    max-width: 400px;
                    padding: 215px 0 90px 95px;
                }

                > .nav-item {
                    > .nav-link {
                        padding: 0 15px;

                        font-family: $font-family-roboto-slab;
                        font-size: 1rem;
                        font-weight: $font-weight-bold;
                        line-height: 36px;
                        color: $green;

                        @include media-breakpoint-up(md) {
                            padding: 0 25px 0 0;
                        }


                        @include media-breakpoint-up(md) {
                            font-size: 1.2rem;
                        }

                        @include media-breakpoint-up(xl) {
                            font-size: 1.4rem;
                        }

                        &:hover {
                            color: $green-light;
                        }
                    }

                    &.active {
                        .nav-link {
                            color: $green-light;
                        }
                    }

                    &.dropdown {
                        position: unset;

                        @include media-breakpoint-up(md) {
                            position: relative;
                        }

                        > .nav-link {
                            &::after {
                                margin: 0 0 0 5px;
                                padding: 0;

                                vertical-align: 0;
                                border: none;

                                font-family: 'Font Awesome 5 Pro';
                                font-size: 18px;
                                font-weight: 400;
                                content: '\f178';
                            }
                        }
                    }

                    .dropdown-menu {
                        position: relative;
                        top: 0;
                        
                        margin: 0;
                        padding: 0 0 0 15px;

                        background-color: transparent;
                        border: none;

                        @include media-breakpoint-up(sm) {
                            position: absolute;
                            top: 90px;
                            left: 175px;

                            width: calc(100vw - 175px);
                        }

                        @include media-breakpoint-up(md) {
                            top: 0;
                            left: 100%;

                            width: calc(100vw - 320px);
                            padding: 0 0 0 50px;
                        }

                        .nav-item {
                            .nav-link {
                                @extend .position-relative;
                                padding: 7px 15px 7px 35px;
                                
                                font-family: $font-family-roboto-slab;
                                font-size: 1rem;
                                font-weight: $font-weight-bold;
                                line-height: 22px;
                                color: $green;

                                @include media-breakpoint-up(sm) {
                                    color: $white;
                                }

                                &::before {
                                    @extend .position-absolute;
                                    left: 0;
                                    padding: 0;
    
                                    vertical-align: 0;
                                    border: none;
    
                                    font-family: 'Font Awesome 5 Pro';
                                    font-size: 18px;
                                    font-weight: 400;
                                    content: '\f178';
                                }

                                &:hover {
                                    color: $green-light;
                                }
                            }

                            &.active {
                                .nav-link {
                                    color: $green-light;
                                }
                            }
                        }
                    }
                }
            }

            .navbar-text {
                @extend .position-absolute;
                right: 50px;
                bottom: 50px;

                @extend .d-none;
                @extend .d-sm-block;
                max-width: 340px;

                text-align: right;
                color: $white;

                a {
                    color: $white;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    
    }

/*  overlay
    ========================================================================== */
    &.header-overlay {
        .header-main {
            /* language-menu */
            .site_switcher {
                .language {
                    &::before,
                    &::after {
                        border-color: $white;
                        color: $white;
                    }

                    .nav-item {
                        .nav-link {
                            color: $white;
                        }
                    }
                }
            }

            /* book-button */
            .book-button {
                border-color: $white;

                &:hover {
                    border-color: $orange;
                }
            }
        }
    }

/*  sticky
    ========================================================================== */
    &.sticky {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;

        width: 100%;

        .header-main {
            .logo:not(.logo-overlay) {
                max-width: 200px;
                height: 110px;
                padding: 0 35px;
            }
        }
    }
}