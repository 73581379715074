/*  bootstrap > colors
    ========================================================================== */
    $black:         #000000;
    $green:         #4d9d45;
    $green-light:   #8dc63f;
    $green-dark:    #125c1c;
    $orange:        #d98c19;
    $gray:          #E0E4E7;
    $dark-gray:		#1a2226;
    $light-gray:	#b8bec3;

/*  bootstrap > theme-colors
    ========================================================================== */
    $primary:       $green;
    $secondary:     $orange;

    $dark:          $green-dark;

/*  bootstrap > body
    ========================================================================== */
    $body-color:        $black;
    $link-color:        $primary;

/*  bootstrap > font
    ========================================================================== */
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap');

    $font-family-open-sans: 'Open Sans', sans-serif;
    $font-family-roboto-slab: 'Roboto Slab', serif;
    $font-family-base: $font-family-open-sans;

/*  bootstrap > typography
    ========================================================================== */   
    $headings-font-family: $font-family-roboto-slab;
    $headings-color: $green-dark;

    /*  misc
    ========================================================================== */   
    
    /* transition */
    a,
    .btn,
    .owl-btn,
    .card-btn { transition: 0.5s !important;}

    /* gradient (extend) */
    .gradient {
		background: rgb(163,206,75);
		background: linear-gradient(90deg, rgba(163,206,75,1) 0%, rgba(119,170,0,1) 100%);
    }

    /*

    Logo: 310px breed

    H1: 36
    H2: 30
    H3: 24
    H4: 20
    H5: 18
    H6: 16

    Groen: 4d9d45
    Groen (licht): 8dc63f
    Groen (donker): 125c1c
    Oranje: d98c19   

    Button:
    36px
    Roboto Slab 16px bold    

    Gradient:
    a3ce4b -> 77aa00

    Grijs (background): dfe4e7

    */

// 3W MEDIA - CUSTOM STYLING
    $theme-color-primary: 			$green-light;
    $theme-color-primary-dark: 		darken($theme-color-primary, 10%);
    
    $theme-color-secondary: 		$orange;
    $theme-color-secondary-dark: 	darken($theme-color-secondary, 10%);
    
    $theme-color-tertiary: 			$orange;
    $theme-color-tertiary-dark: 	darken($theme-color-tertiary, 10%);

    // TOMMY
    $zeb-color-primary: 			$theme-color-primary;
    $zeb-color-secondary: 		    $theme-color-secondary;
    $zeb-color-tertiary: 			$theme-color-primary-dark;