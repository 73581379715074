.owl-carousel.slider .owl-nav {
    display: flex !important;
    top: 0;
    left: auto;
    right: 0;
    bottom: auto;
    .owl-prev,
    .owl-next {
        position: static;
        width: auto;
        height: auto;
        margin-left: 10px;
        color: $white;
        font-size: 40px;
        line-height: 1;
        transition: color 0.5s;
        &:hover {
            color: $green-dark;
        }
    }
}