&.accommodation_category_overview,
&.accommodation_category_detail,
&.accommodation_detail {
	.mini-sab {
		@extend .d-none;
		visibility: hidden;
	}

	.accommodation_detail {
		.info {
			&.full-width {
				@include make-col-ready();
				@include make-col(12);
			}
		}

		.tommy-info {
			@include make-col-ready();
			@include make-col(12);
		}
	}
}

&.accommodation_search_book {
	.content {
		> .container:not(.container-custom) {
			padding-bottom: 140px;
		}

		.container-custom {
			max-width: unset;
		}
	}

	.search_book {
		@extend .position-relative;
		padding: 50px 0 70px 0;

		@extend .gradient;
		
		&:before {
				content: "";

				position: absolute;
				right: 0;
				bottom: 100%;
				left: 0;
				z-index: 50;
				
				width: 100%;
				height: 33px;

				background: url('/images/grass-top.svg');
				background-position: center top;  
				background-size: cover;  
		}

		&:after {
				content: "";

				position: absolute;
				top: 100%;
				right: 0;
				left: 0;
				z-index: 50;

				width: 100%;
				height: 34px;

				background: url('/images/grass-bottom.svg');
				background-position: center bottom;  
				background-size: cover;  
		}

		.container-default {
			&:before {
					content: "";

					position: absolute;
					top: -80px;
					right: 50%;
					z-index: 50;
					
					width: 110px;
					height: 115px;
					margin: 0 -55px 0 0;

					background: url('/images/element-tree.png');
					background-position: center top;  
					background-size: cover;  
			}
		}
	}
}

&.accommodation_detail {
	.main {
		> .content {
				padding-top: 12px;

			> .container {
				max-width: unset;
				@extend .p-0;
			}
		}
	}

	.accommodation_detail {
		@extend .m-0;

		.persons {
			font-size: 20px;
			text-transform: uppercase;
			color: $green-light;

			i {
				margin: 0 10px 0 0;

				font-size: 20px;
				color: #476320;
			}
		}

		.accommodation-properties {
			justify-content: center;

			&__item { margin: 0 30px; }

			&__icon {
				font-size: 20px;
				color: #476320;
			}

			&__label {
				font-size: 20px;
				text-transform: uppercase;
				color: $green-light;
			}
		}

		.characteristics {
			margin: 0;
			padding: 0;

			list-style: none;

			&__item { margin: 0 0 10px 0; }

			&__title { font-weight: $font-weight-bold; }

			&__sub-list {
				margin: 0;
				padding: 0;

				list-style: none;

				&__item {
					padding: 0 0 0 15px;
				}

				&__icon {
					margin: 0 10px 0 0;

					font-size: 20px;
					color: #476320;
				}

				&__label {
					font-size: 20px;
					text-transform: uppercase;
					color: $green-light;
				}
			}
		}
	}

	.intro {
		@include make-col(12);

		margin-bottom: 6vh;
	}

	.btn-back {
		@extend .d-inline-block;
		margin-bottom: 45px !important;

		text-transform: uppercase;
		text-decoration: none;
	}

	.content-acco {
		@include make-col(12);
		padding: 70px 0 90px 0;

		background-color: rgba(#2D4C60, .15);

		.content-acco-row {
			@include make-row();
		}

		.info-extra {
			.gallery {
				@extend .p-0;
			}
		}
	}

	.tommy-holder {
		@include make-col(12);

		padding: 40px 0 0 0;
	}
}