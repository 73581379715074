.btn {
    padding: 0.32rem 1.5rem;
    border-radius: 5px;
    border-color: $primary;
    background-color: $primary;
    color: $white;
    font-size: 16px;
    font-weight: 700;
    font-family: $font-family-roboto-slab;
    text-decoration: none;
    &.btn-primary {
        &:hover {
            background-color: $orange;
            border-color: $orange;
        }
    }
    &.btn-secondary {
        border-color: $white;
        background-color: $white;
        color: $green-dark;
        &:hover {
            background-color: $green-dark;
            border-color: $green-dark;
            color: $white;
        }
    }
}