html { font-size: initial; }
body { font-size: $font-size-base; }

#TommyBookingSupport {
  .tbs-container-fluid {
    @extend .px-0;
  }

  .tbs-navbar-collapse {
    @extend .px-0;
  }
  
  .tbs-nav {
    li {
      font-size: 14px;
    }
  }

  .tbs-page-overzicht {
    #coupon_code {
      h2 {
        margin-top: 20px;
      }
    }
  }

  .tbs-page-artikelen {
    h4 {
      color: #000;
      font-size: 16px;
      text-transform: none;
    }
  }

}
