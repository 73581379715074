.footer {
 
    /* partners */
    .partners {
        background-color: $white;
        padding: 5vh 0;
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            flex-wrap: wrap;      
            align-items: center;
            justify-content: center;
        }
        ul li { margin: 10px 2vw;}  
    }

    /* page-ending */
    .page-ending {
        position: relative;
        padding: 6vh 0;
        background-color: $green;
        color: $white;
        &:before {
            content: "";
            z-index: 50;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 100%;
            width: 100%;
            height: 37px;
            background: url('/images/footer-grass.svg');
            background-position: center top;
            background-size: cover;
        }
        &:after {
            display: none;
            content: "";
            z-index: 40;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 100%;
            width: 100%;
            height: 57px;
            background: url('/images/footer-grass-2.svg');
            background-position: center top;
            background-size: cover;
        }
        h5 {
            color: $white;
            font-size: 18px;
        }
        a:not(.btn) {
            color: $white;
            text-decoration: underline;
            &:hover {
                color: $green-dark;
            }    
        }
        a[href^="tel:"],
        a[href^="mailto:"] {
            text-decoration: none;
        }

        /* leaves */
        .leaves {
            display: none;
            z-index: 100;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 25vw;
            max-width: 105px;
            margin: -125px auto 0 auto;
            animation: shake 1s infinite both;
        }
        @keyframes shake {
            0% { transform: rotate(0);}
            50% { transform: rotate(-5deg);}
            100% { transform: rotate(0);}
        }

    }

    /* copyright */
    .copyright {
        padding: 30px 0;
        background-color: $white;
        font-size: 14px;
        text-align: center;
        .list {
            justify-content: center;
            .list-item {
                margin: 0 10px;
                .link {
                    color: $green;
                    &:hover { color: $primary;}
                }
            }
        }
    }

}