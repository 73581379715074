    &.sticky {
        padding: $body-sticky-padding;
    }

/*  intro
    ========================================================================== */
    .intro {
        text-align: center;
        p {
            margin-left: auto;
            margin-right: auto;

            @extend .container;
        }
    }

/*  content
    ========================================================================== */
    .content-padding {
        padding: $content-padding;
    }
    .content {
        &.bg-light {
            background-color: $gray !important;
        }
        h4 {
            color: $green;
            font-weight: 400;
            text-transform: uppercase;
        }
    }


/*  content-blocks
    ========================================================================== */
    &.home .content-blocks {
        display: none;
    }
    .content-blocks {
        position: relative;
        margin-top: 33px;
        padding: 6vh 0;
        @extend .gradient;
        &:before {
            content: "";
            z-index: 50;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 100%;
            width: 100%;
            height: 33px;
            background: url('/images/grass-top.svg');
            background-position: center top;  
            background-size: cover;  
        }
        .owl-carousel { padding-top: 60px;}
    }

/*  align-items: center for sections with images
    ========================================================================== */
.container-two-columns {
    .container-holder {
        &:has(.column img) {
            align-items: center;

            p:has(img) {
                margin-bottom: 0;
            }
        }
    }
}