.mini-sab {
    position: relative;
    z-index: 998;
    background-color: rgba($color: #3b5965, $alpha: 0.9);
    @include media-breakpoint-down(lg) {
        margin: -75px 10% 0 10%;
        padding: 15px;
        border-radius: 6px;
        background-color: rgba($color: #3b5965, $alpha: 1);
    }
    @include media-breakpoint-up(xl) {
        height: 0;
        padding: 0;
        box-shadow: none;
    }
    .container-holder {
        @include media-breakpoint-up(xl) {
            position: absolute;
            z-index: 100;
            left: 15px;
            right: 15px;
            bottom: 100%;
            max-width: 1150px;
            margin: 0 auto 50px auto;
            padding: 20px 50px;
            border-radius: 6px;
            background-color: rgba($color: #3b5965, $alpha: 0.9);
            box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.15);
            color: $white;
        }
        
        h4 {
            margin: 0 0 20px 0;

            font-family: $font-family-roboto-slab;
            font-size: 24px;
            font-weight: $font-weight-bold;
            text-align: center;
            color: $white;
        }
    }
}