/* ==========================================================================
   body
   ========================================================================== */
   $body-padding: 90px 0 0 0;
   $body-sticky-padding: 90px 0 0 0;

 
/* ==========================================================================
   containers
   ========================================================================== */
   $container-padding: 0 15px;
   

/* ==========================================================================
   content
   ========================================================================== */
   
   $content-padding: 6vh 0;

/*  content > h1
    ========================================================================== */
    $content-h1-font_size: 1.875rem; /* 30px */
    $content-h1-font_size_sm: 2.25rem; /* 36px */
    $content-h1-color: $headings-color;

/*  content > h2
    ========================================================================== */
    $content-h2-font_size: 1.5rem; /* 24px */
    $content-h2-font_size_sm: 1.875rem; /* 30px */
    $content-h2-color: $headings-color;

/*  content > h3
    ========================================================================== */
    $content-h3-font_size: 1.25rem; /* 20px */
    $content-h3-font_size_sm: 1.5rem; /* 24px */
    $content-h3-color: $headings-color;

/*  content > h4
    ========================================================================== */
    $content-h4-font_family: $font-family-open-sans;
    $content-h4-font_size: 1.25rem; /* 20px */
    $content-h4-font_size_sm: 1.25rem; /* 20px */
    $content-h4-font_weight: 400;
    $content-h4-color: $headings-color;

/*  content > h5
    ========================================================================== */
    $content-h5-font_size: 1.125rem; /* 18px */
    $content-h5-font_size_sm: 1.125rem; /* 18px */
    $content-h5-color: $headings-color;

/*  content > h6
    ========================================================================== */
    $content-h6-font_size: 1; /* 16px */
    $content-h6-font_size_sm: 1; /* 16px */
    $content-h6-color: $headings-color;

/*  content > ol.custom-list
    ========================================================================== */

    /* content > ol.custom-list > icon */
    $content-ol-custom-list-item-icon: '\f00c';
    $content-ol-custom-list-item-icon-font_size: $font-size-base;
    $content-ol-custom-list-item-icon-font_weight: $font-weight-bold;


/* ==========================================================================
    card
    ========================================================================== */

/*  card > card-caption
    ========================================================================== */
    /* card > card-title */
    $card-title-color: $headings-color;
    $card-title-font_size: $font-size-base * 1.25;
    $card-title-font_size_sm: $font-size-base * 1.5;


/* ==========================================================================
   owl-carousel
   ========================================================================== */
   $carousel-min_height: 55vh;
   $carousel-min_height_lg: 80vh;