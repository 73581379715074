.footer {
    .custom-list {
        padding: 0;
        list-style: none;
    }
    .custom-list li  {
        padding-left: 24px;
    }
    .custom-list li::before {
        content: "\f00c";
        font-family: "Font Awesome 5 Pro";
        float: left;
        margin-left: -24px;
        font-size: 14px;
        font-weight: 900;
    }
}