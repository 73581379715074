.eyecatcher {
    .special-visual {
        z-index: 25;
        position: absolute;
        left: 50px;
        bottom: 0;
        width: 30vw;
        max-width: 250px;
        max-height: 450px;
        @include media-breakpoint-up(md) {
            bottom: -50px;
        }
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}