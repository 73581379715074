/* service_overview + service_category */
&.service_overview,
&.service_category {
  .content {
    padding-top: 12px;
  }

  .container-custom {
    @extend .position-relative;
    max-width: unset;
    padding: 75px 0 90px 0;

    @extend .gradient;
    
    &:before {
        content: "";
        z-index: 50;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 100%;
        width: 100%;
        height: 33px;
        background: url('/images/grass-top.svg');
        background-position: center top;  
        background-size: cover;  
    }
    
    &:after {
        content: "";
        z-index: 50;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        width: 100%;
        height: 34px;
        background: url('/images/grass-bottom.svg');
        background-position: center bottom;  
        background-size: cover;  
    }

    .container-holder {
      @extend .position-relative;

      &:before {
          content: "";

          position: absolute;
          top: -150px;
          right: 50%;
          z-index: 50;
          
          width: 145px;
          height: 145px;
          margin: 0 -72.5px 0 0;

          background: url('/images/element-leaves.png');
          background-position: center top;  
          background-size: cover;  
      }
    }
  }

  .service_overview,
  .service_category_overview {
    max-width: 1020px;
    margin: 0 auto;

    .card {
      @extend .d-flex;
      @extend .flex-row;
      @extend .flex-wrap;
      @extend .align-items-start;

      background-color: transparent;
      border: none;
      border-radius: 0;

      .card-image {
        @include make-col(12);

        @include media-breakpoint-up(md) {
          @include make-col(6);

          order: 1;
          margin: 60px 0 0 -90px;
        }

        img {
          border-radius: 0;
        }
      }

      .card-body {
        @extend .justify-content-center;
        @include make-col(12);
        padding: 55px 25px;

        @include media-breakpoint-up(md) {
          @include make-col(6);

          order: 0;

          min-height: 320px;
          margin: 0 0 0 90px;
          padding: 70px 130px 70px 40px;
        }
      }

      .card-caption {
        flex: 0 0 auto;
      }
    }

    .item {
      @include make-col(12);
      margin: 0 0 50px 0;

      &:nth-child(even) {
        .card {
          .card-image {
            order: 0;
            margin: 0;
          }

          .card-body {
            order: 1;

            @include media-breakpoint-up(md) {
              margin: 60px 0 0 -60px;
              padding: 70px 40px 70px 100px;
            }
          }
        }
      }
    }
  }
}

/* service_overview */
&.service_overview {
  .content {
		> .container:not(.container-custom) {
			padding-bottom: 140px;
		}
	}
}

/* service_category + service_detail */
&.service_category,
&.service_detail {
  .content {
		> .container {
      max-width: unset;
		}
  }

  .service_category_detail,
  .service_detail {    
    .info {
      padding: 0 15px 100px 15px !important;

      @include media-breakpoint-up(lg) {
        @include make-col(12);
      }

      .btn-back {
        @extend .d-inline-block;
        margin-bottom: 45px !important;
    
        text-transform: uppercase;
        text-decoration: none;
      }
    }

    .info-extra {
      padding: 125px 15px 100px 15px !important;

      @extend .gradient;
      
      &:before {
          content: "";
          z-index: 50;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 100%;
          width: 100%;
          height: 33px;
          background: url('/images/grass-top.svg');
          background-position: center top;  
          background-size: cover;  
      }
      
      &:after {
          content: "";
          z-index: 50;
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          width: 100%;
          height: 34px;
          background: url('/images/grass-bottom.svg');
          background-position: center bottom;  
          background-size: cover;  
      }
      
      @include media-breakpoint-up(lg) {
        @include make-col(12);
      }

      .container {
        max-width: 1080px;

        &:before {
          content: "";

          position: absolute;
          top: -87px;
          right: 50%;
          z-index: 50;
          
          width: 175px;
          height: 175px;
          margin: 0 -87.5px 0 0;

          background: url('/images/element-girl-rope.png');
          background-position: center top;  
          background-size: cover;  
        }
      }

      .gallery {
        @extend .justify-content-center;
        @extend .align-items-start;

        .gallery-item {
          @include make-col(12);
          margin: 0;
          padding: 0;

          border: 10px solid #9CC83F;

          @include media-breakpoint-up(sm) {
            @include make-col(6);
          }

          &:nth-child(1) { z-index: 100; }
          &:nth-child(2) { margin: 30px 0 0 -5px; }
          &:nth-child(3) { margin: -90px 0 0 0; }

          .gallery-caption {
            top: unset;
            right: 50%;
            bottom: -70px;
            left: unset;

            width: 100px;
            height: 100px;
            margin: 0 -50px 0 0;
            padding: 50px 0 0 0;

            background-color: $white;
            border-radius: 50%;

            font-family: $font-family-roboto-slab;
            font-size: $font-size-base * 1.875;
            font-weight: $font-weight-bold;
            text-align: center;
            color: $green-dark;

            &::after {
              @extend .position-absolute;
              top: 20px;

              font-family: "Font Awesome 5 Pro";
              font-size: 36px;
              line-height: 37px;
              color: $green;

              content: "\f002";
            }
          }
          &:hover {
            .gallery-caption {
              background-color: $green-dark;
              color: $white;
            }
          }
        }
      }
    }
  }
}

/* service_category */
&.service_category {
  .service_category_detail {
    &.no-gallery {
      .info {
        @extend .mb-0;
        padding-bottom: 50px !important;
      }

      .info-extra {
        @extend .d-none;
      }
    }
  }
}

/* service_detail */
&.service_detail {
  .content {
    padding-top: 12px;
    @extend .pb-0;
  }
}