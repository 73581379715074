/*  default
    ========================================================================== */
    .card {
        .card-body {
            .card-caption {
                .card-description {
                    &:after {
                        display: none !important;
                    }
                }
            }
        }
    }

/*  home-blocks
    ========================================================================== */
    .home-blocks {
        .card {
            background: none;
            border: none;
            border-radius: none;
            .card-image {
                background: none;
            }
            .card-body {
                background: none;
                .card-caption {
                    text-align: center;
                    .card-title {
                        color: $white;
                        text-align: center;
                    }
                    .card-subtitle {
                        color: $white;
                        text-align: center;
                    }
                }
                .card-buttons {
                    .card-btn {
                        background: none;
                        border: none;
                        &:hover { color: $green-dark;}
                    }
                }
            }
        }
    }


/*  content-blocks
    ========================================================================== */
    .content-blocks {
        .card {
            background: none;
            border: none;
            border-radius: none;
            .card-image {
                background: none;
            }
            .card-body {
                background: none;
                .card-caption {
                    text-align: center;
                    .card-title {
                        color: $white;
                        text-align: center;
                    }
                    .card-subtitle {
                        color: $white;
                        text-align: center;
                    }
                }
                .card-buttons {
                    .card-btn {
                        background: none;
                        border: none;
                        &:hover { color: $green-dark;}
                    }
                }
            }
        }
    }

/*  accommodation-overview
    ========================================================================== */
    .accommodation_overview {
        .card {
            border: 1px solid #EEEEEE;
            border-radius: 0;
            box-shadow: 6px 6px 12px rgba($black, 0.15);

            .card-body {
                padding: 25px;
            }

            .card-caption {
                .card-title {
                    color: #476320;
                }
                .card-title-link {
                    .card-title {
                        min-height: 56px;
                        max-height: 56px;
                        overflow: hidden;

                        color: #476320;
                    }

                    &:hover {
                        .card-title {
                            color: $green-light;
                        }
                    }
                }

                .card-text {
                    min-height: 140px;
                    max-height: 140px;
                    overflow: hidden;

                    font-size: 14px;

                    * {
                        font-size: inherit;
                    }

                    p {
                        min-height: 65px;
                        max-height: 65px;
                        overflow: hidden;
                    }

                    .list {
                        .list-item {
                            .icon {
                                color: #476320;

                                i {
                                    font-weight: $font-weight-bold;
                                }
                            }

                            .label {
                                text-transform: uppercase;
                                color: $green-light;
                            }
                        }
                    }
                }
            }
        }
    }